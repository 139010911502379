export default [
  {label: 'Facebook', value: 'facebook'},
  {label: 'Bigo Ads', value: 'bigo'},
  {label: 'NewsBreak', value: 'newsbreak'},
  {label: 'Snapchat', value: 'snapchat'},
  {label: 'TikTok', value: 'tiktok'},
  {label: 'Google', value: 'google'},
  {label: 'Microsoft Ads', value: 'bing'},
  {label: 'Taboola', value: 'taboola'},
  {label: 'Outbrain', value: 'outbrain'},
  {label: 'RevConvent', value: 'revconvent'},
]
