<script setup>
import {onMounted, defineEmits, ref, defineProps, defineExpose} from 'vue'

// Props & emits
let emit = defineEmits(['file-updated', 'delete-file', 'changed'])

const props = defineProps({
  activeRequestFields: {
    type: Object,
    default: null,
  },
  disabled: {
    type: Boolean,
    default: false,
  },
})

const defaultFormData = {
  geo: '',
  websites: '',
  gambling_type: '',
  license_link: '',
}
let formData = ref(structuredClone(defaultFormData))

// Methods
onMounted(() => {
  init()
})

const init = () => {
  if (props.activeRequestFields) {
    formData.value = Object.assign(formData.value, props.activeRequestFields)
  }
}

const resetForm = () => {
  formData.value = structuredClone(defaultFormData)
}

const onChange = () => {
  emit('changed', {fields: formData.value})
}

defineExpose({resetForm})
</script>

<template>
  <div class="form-group">
    <label for="health_insurance-wl-websites">Website URL(s) *</label>
    <div class="input-group">
      <textarea
        v-model="formData.websites"
        @change="onChange"
        :disabled="disabled"
        class="form-control"
        id="health_insurance-wl-websites"
        rows="2"
        required
      />
    </div>
  </div>
  <div class="form-group">
    <label for="health_insurance-wl-certification_url"
      >Link to page on G2 site verifying Health Insurance Certification*</label
    >
    <div class="input-group">
      <input
        v-model="formData.certification_url"
        @change="onChange"
        :disabled="disabled"
        class="form-control"
        id="health_insurance-wl-certification_url"
        type="url"
        required
      />
    </div>
  </div>
</template>
