import {defineStore} from 'pinia'
import axios from '@/plugins/axios'

export const useCompanyStore = defineStore({
  id: 'companyStore',
  state: () => ({
    companies: [],
    companiesLoading: false,
    error: null,
    showCompanyModal: false,
    companyToEdit: null,
  }),
  getters: {
    companiesOptions: state => {
      return state.companies.map(({hash, title}) => ({value: hash, label: title}))
    },
    externalCompaniesOptions: state => {
      return state.companies
        .filter(n => n.is_internal === 0)
        .map(({hash, title}) => ({
          value: hash,
          label: title,
        }))
    },
    companiesWithEmptyOptions: state => {
      let result = state.companies.map(({hash, title}) => ({value: hash, label: title}))

      result.unshift({value: 0, label: 'No company'})

      return result
    },
    internalCompaniesOptions: state => {
      return state.companies
        .filter(n => n.is_internal === 1)
        .map(({hash, title, is_sf}) => ({
          value: hash,
          label: title,
          is_sf: !!is_sf,
        }))
    },
    verticals: () => {
      return [
        'Dating',
        'Sweepstakes',
        'Apps',
        'Social Casino',
        'Casino',
        'Horoscopes',
        'Insurance',
        'Auto Insurance',
        'Home Insurance',
        'Ecom',
        'Search',
        'Home Improvement',
        'Solar',
        'Nutra',
        'Travel',
        'Comics',
        'Casual gaming',
        'Mainstream gaming',
        'Forex',
        'Crypto',
        'Personal Finance',
        'Education',
        'Health & Fitness',
      ]
    },
  },
  actions: {
    async getCompanies(filters = {}) {
      this.error = null
      this.companiesLoading = true

      try {
        const {data} = await axios.get('companies', {params: filters})

        this.companies = data
        return data
      } catch (responseError) {
        this.error = responseError.data
      } finally {
        this.companiesLoading = false
      }
    },
    async createCompany(payload) {
      const {data} = await axios.post('companies', payload)

      return data
    },
    async updateCompany(payload, hash) {
      await axios.patch(`companies/${hash}`, payload)

      return true
    },

    async getCompanyTags() {
      const {data} = await axios.get('company-tags')

      return data
    },

    async getCompanyToEdit(company_hash) {
      try {
        let result = await this.getCompanies({
          with: ['sale_manager', 'media_planner', 'media_planner_2', 'csm', 'tags', 'verticals', 'first_user'],
          with_count: ['users', 'quick_books_customers', 'documents', 'invoices', 'payments', 'overdraft_requests'],
        })

        this.companies = result.map(company => ({
          ...company,
          balances_diff: Math.abs(company.qb_balance) - company.balance,
        }))
      } catch (error) {
        //
      }

      this.companyToEdit = this.companies.find(item => item.hash === company_hash)
      return this.companyToEdit
    },
    async openCompanyModal(company_hash) {
      await this.getCompanyToEdit(company_hash)
      this.showCompanyModal = true
    },
  },
})
