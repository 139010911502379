<script setup>
import {defineProps, ref, onMounted, defineEmits} from 'vue'
import TheModal from '@/components/ui/TheModal.vue'
import {storeToRefs} from 'pinia'
import {useClickupStore} from '@/views/clickup-list/ClickupStore'

const {clickupAuthLink} = storeToRefs(useClickupStore())

// Props & emits
const props = defineProps({
  id: {
    type: String,
    default: null,
  },
})
const emit = defineEmits(['hidden'])
const modalId = props.id ?? 'clickup-auth-modal'

// Refs
const theModal = ref()

// Methods
onMounted(() => {
  openModal()
})

const openModal = () => {
  theModal.value.handleOpenModal()
}

const emitHidden = () => {
  emit('hidden')
}
</script>

<template>
  <TheModal ref="theModal" @hidden="emitHidden" :id="modalId" :cant-close="true" :no-header="true">
    <div class="modal-body py-5">
      <h4 class="text-center fw-bolder mb-4">Please sign in to ClickUp to create your task</h4>
      <div class="d-flex justify-content-center">
        <a :href="clickupAuthLink" target="_blank" rel="nofollow noopener noreferrer" class="btn btn-outline-light">
          <img src="@/assets/images/clickup-full.png" alt="clickup" class="max-w-150-px" />
        </a>
      </div>
    </div>
  </TheModal>
</template>
