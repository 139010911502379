import {defineStore} from 'pinia'
import axios from '@/plugins/axios'
import {http_build_query, isLocal} from '@/plugins/helpers'

export const useClickupStore = defineStore({
  id: 'clickupStore',
  state: () => ({
    clickupLoading: false,
    error: null,
    showClickupAuthModal: false,
    clickupAuthLink: '',
    hasClickupToken: true,
  }),
  getters: {},
  actions: {
    async getClickupTasks(filters = {}) {
      this.error = null
      this.clickupLoading = true

      try {
        const {data} = await axios.get('click-up-tasks', {params: filters})

        return data
      } catch (responseError) {
        this.error = responseError.data
      } finally {
        this.clickupLoading = false
      }
    },
    async openClickupAuthModal() {
      this.showClickupAuthModal = true
    },
    async checkClickUpToken(userClickUpToken, userHash) {
      if (userClickUpToken || isLocal()) {
        return
      }

      this.hasClickupToken = false

      this.clickupAuthLink =
        'https://app.clickup.com/api?' +
        http_build_query({
          client_id: process.env.VUE_APP_CLICKUP_CLIENT_ID,
          redirect_uri: `${process.env.VUE_APP_API_URL}/clickup/oauth?user_hash=${userHash}`,
        })
    },
    async checkClickUpTokenAndOpenModal(userClickUpToken, userHash) {
      await this.checkClickUpToken(userClickUpToken, userHash)

      if (!this.hasClickupToken) {
        await this.openClickupAuthModal()
      }
    },
  },
})
