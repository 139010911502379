<script setup>
import {defineProps, defineEmits, computed, watch} from 'vue'
import Multiselect from '@vueform/multiselect'

const emit = defineEmits(['change', 'update:modelValue', 'search-change', 'deselect'])

let props = defineProps({
  modelValue: {},

  options: {
    type: Array,
    required: true,
  },
  required: {
    type: Boolean,
    default: false,
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  placeholder: {
    type: String,
    default: 'Select a value',
  },
  searchable: {
    type: Boolean,
    default: true,
  },
  allow_clear: {
    type: Boolean,
    default: true,
  },
  can_deselect: {
    type: Boolean,
    default: true,
  },
  mode: {
    type: String,
    default: 'single',
  },
})

const isValid = computed(() => !props.required || !!props.modelValue)

const onDeselect = value => {
  emit('update:modelValue', '')
  emit('deselect', value)
}
const onChange = value => {
  emit('update:modelValue', value)
  emit('change', value)
}
const onSearchChange = value => {
  emit('search-change', value)
}

watch(
  () => props.options,
  () => {
    if (props.options.length === 1 && props.allow_clear === false) {
      emit('update:modelValue', props.options[0].value)
    }
  },
  {immediate: true},
)
</script>

<template>
  <div :class="['the-select-wrapper', isValid ? 'valid' : 'invalid', mode === 'tags' ? 'tags' : '']">
    <input v-if="required && !isValid" class="d-none" required />
    <Multiselect
      :value="modelValue"
      :options="options"
      :searchable="searchable"
      :disabled="disabled"
      :placeholder="placeholder"
      :mode="mode"
      @input="onChange"
      @deselect="onDeselect"
      @search-change="onSearchChange"
      :canDeselect="can_deselect"
    >
      <template v-if="!allow_clear" #clear><span /></template>

      <template #singlelabel="{value}">
        <div class="multiselect-single-label">
          <img class="character-label-icon max-w-20 me-1" :src="value.icon" /> {{ value.label }}
        </div>
      </template>
      <template v-slot:option="{option}">
        <img class="character-option-icon max-w-20 me-1" :src="option.icon" /> {{ option.label }}
      </template>
    </Multiselect>
  </div>
</template>
