<script setup>
import {onMounted, defineEmits, ref, defineProps, defineExpose} from 'vue'
import {openUrl} from '@/plugins/helpers'
import licenseTypeOptions from '@/views/ad-account-requests/white-lists/components/licenseTypeOptions'
import TheSelect from '@/components/ui/TheSelect.vue'

// Props & emits
let emit = defineEmits(['file-updated', 'delete-file', 'changed'])

const props = defineProps({
  activeRequestFields: {
    type: Object,
    default: null,
  },
  disabled: {
    type: Boolean,
    default: false,
  },
})

let requestFormData = new FormData()
let uploadedFileName = ref('')
const licenseType = ref('file')
const defaultFormData = {
  geo: '',
  websites: '',
  proving_documents: '',
  proving_documents_link: '',
}
let formData = ref(structuredClone(defaultFormData))

// Methods
onMounted(() => {
  init()
})

const init = () => {
  if (props.activeRequestFields) {
    formData.value = Object.assign(formData.value, props.activeRequestFields)
  }
}
const resetLicenseData = () => {
  deleteUploadedFile('health_insurance_license')
  formData.value.proving_documents_link = ''
}
const resetForm = () => {
  formData.value = structuredClone(defaultFormData)
}
const onFileUploaded = (event = null, file_type) => {
  if (!event.target.files[0]) {
    return
  }

  requestFormData.set(`fields[${file_type}]`, event.target.files[0], event.target.files[0].name)
  uploadedFileName.value = event.target.files[0].name
  updateFormPayload()
}

const deleteUploadedFile = item => {
  requestFormData.delete(item)

  uploadedFileName.value = ''

  emit('delete-file', item)
}

const updateFormPayload = () => {
  emit('file-updated', requestFormData)
}

const onChange = () => {
  emit('changed', {fields: formData.value})
}

defineExpose({resetForm})
</script>

<template>
  <div class="form-group">
    <label for="social_casino-wl-geo">Geo *</label>
    <div class="input-group">
      <input
        v-model="formData.geo"
        @change="onChange"
        class="form-control"
        id="social_casino-wl-geo"
        type="text"
        :disabled="disabled"
        required
      />
    </div>
  </div>
  <div class="form-group">
    <label for="social_casino-wl-websites">Website(s) *</label>
    <div class="input-group">
      <textarea
        v-model="formData.websites"
        @change="onChange"
        :disabled="disabled"
        class="form-control"
        id="social_casino-wl-websites"
        rows="2"
        required
      />
    </div>
  </div>

  <div class="alert alert-light mb-3">
    <label :for="[`wl-proving_documents-${licenseType === 'file' ? 'file-upload' : 'link'}`]">
      Documents proving that the product is social casino
      <small class="text-muted"> (if available)</small>
    </label>
    <div class="row">
      <div class="col">
        <div v-if="licenseType === 'file'" class="form-group">
          <div v-if="formData?.proving_documents_url">
            <span @click="openUrl(formData?.proving_documents_url)" class="full-width btn btn-outline-success">
              Open file
            </span>
          </div>
          <div v-else>
            <template v-if="!uploadedFileName">
              <template v-if="disabled">
                <div class="alert alert-info">No file</div>
              </template>
              <template v-else>
                <label for="wl-proving_documents-file-upload" class="full-width btn btn-secondary">
                  <i class="fa fa-upload"></i> Add file
                </label>

                <input
                  class="d-none"
                  @change="onFileUploaded($event, 'proving_documents')"
                  id="wl-proving_documents-file-upload"
                  :disabled="disabled"
                  type="file"
                />
              </template>
            </template>
            <template v-else>
              <span class="full-width btn btn-outline-success">
                {{ uploadedFileName }}
                <button type="button" class="btn-close circle-btn" @click="deleteUploadedFile('proving_documents')" />
              </span>
            </template>
          </div>
        </div>
        <div v-else class="form-group">
          <input
            v-model="formData.proving_documents_link"
            type="url"
            placeholder="Add link"
            class="form-control"
            id="wl-proving_documents-link"
            @change="deleteUploadedFile('licenses')"
          />
        </div>
      </div>
      <div class="col-3 pl-0" v-if="!activeRequestFields">
        <the-select
          v-model="licenseType"
          :options="licenseTypeOptions"
          @change="resetLicenseData"
          placeholder=""
          :allow_clear="false"
        ></the-select>
      </div>
    </div>
  </div>
</template>
