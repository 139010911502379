<script setup>
import {defineProps} from 'vue'

const props = defineProps({
  size: {
    type: String, // small,
    required: false,
  },
})
</script>

<template>
  <div :class="['the-tooltip', props.size === 'small' ? 'small' : '']">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      class="the-tooltip-icon"
    >
      <path
        d="M17.9988 8.40088C17.9988 8.7979 17.9988 9.19493 17.9988 9.59879C17.9851 9.64671 17.9714 9.70147 17.9645 9.74939C17.9166 10.1122 17.8892 10.4818 17.8071 10.8378C17.3348 12.994 16.2327 14.7532 14.494 16.1154C13.2687 17.0737 11.8792 17.6624 10.339 17.8952C10.0926 17.9294 9.84614 17.9636 9.59972 17.9978C9.2027 17.9978 8.80568 17.9978 8.40181 17.9978C8.35389 17.9841 8.29913 17.9705 8.25122 17.9636C7.88842 17.9157 7.51878 17.8883 7.15599 17.8062C5.02713 17.3407 3.26107 16.266 1.92626 14.541C0.221809 12.3506 -0.346341 9.86575 0.201274 7.15506C0.632521 5.01936 1.7346 3.26699 3.45958 1.92533C5.65004 0.227728 8.13485 -0.347268 10.8455 0.200347C12.9744 0.631594 14.7131 1.74051 16.0684 3.44497C17.0541 4.68395 17.6565 6.09405 17.8961 7.65476C17.9303 7.90803 17.9645 8.15446 17.9988 8.40088ZM1.65245 9.00326C1.65245 13.0556 4.93814 16.3413 8.99734 16.355C13.0497 16.3687 16.3559 13.0625 16.3559 9.00326C16.3559 4.95091 13.0702 1.66522 9.01103 1.65153C4.95868 1.63784 1.65245 4.94406 1.65245 9.00326Z"
        fill="#ABABAB"
      />
      <path
        d="M9.81911 10.9336C9.81911 11.7207 9.81911 12.5011 9.81911 13.2883C9.81911 13.7264 9.61375 14.0207 9.25096 14.1371C8.74441 14.3014 8.23102 13.9591 8.18995 13.432C8.18311 13.3704 8.18311 13.302 8.18311 13.2404C8.18311 11.6865 8.18311 10.1395 8.18311 8.58565C8.18311 8.39399 8.1968 8.20232 8.30632 8.03119C8.48429 7.74369 8.84024 7.58626 9.16881 7.66155C9.53161 7.74369 9.79857 8.02435 9.81226 8.3803C9.8328 8.93476 9.82595 9.48922 9.82595 10.0505C9.81911 10.338 9.81911 10.6324 9.81911 10.9336Z"
        fill="#ABABAB"
      />
      <path
        d="M7.91584 4.90981C7.91584 4.30743 8.41554 3.81458 9.01107 3.82142C9.62029 3.82827 10.0926 4.32112 10.0926 4.9235C10.0858 5.51218 9.59291 6.00504 9.00423 6.00504C8.40185 6.00504 7.909 5.51218 7.91584 4.90981Z"
        fill="#ABABAB"
      />
    </svg>

    <span class="the-tooltip-message"><slot /></span>
  </div>
</template>
