<script setup>
import {onMounted, defineEmits, ref, defineProps, defineExpose, computed} from 'vue'
import {openUrl} from '@/plugins/helpers'
import TheSelect from '@/components/ui/TheSelect.vue'
import licenseTypeOptions from '@/views/ad-account-requests/white-lists/components/licenseTypeOptions'

// Props & emits
let emit = defineEmits(['file-updated', 'delete-file', 'changed'])

const props = defineProps({
  activeRequestFields: {
    type: Object,
    default: null,
  },
  disabled: {
    type: Boolean,
    default: false,
  },
})

const publicPath = process.env.BASE_URL
let requestFormData = new FormData()
let uploadedFileName = ref('')
const licenseType = ref('file')
const defaultFormData = {
  geo: '',
  websites: '',
  certificate_type: '',
  license_link: '',
}
let formData = ref(structuredClone(defaultFormData))

const certficationTypes = [
  'Cryptocurrency exchanges and software wallets',
  'Cryptocurrency hardware wallets; Cryptocurrency coin trust',
  'Complex speculative financial products',
]

const certficationTypeOptions = computed(() => certficationTypes.map(item => ({value: item, label: item})))

// Methods
onMounted(() => {
  init()
})

const init = () => {
  if (props.activeRequestFields) {
    formData.value = Object.assign(formData.value, props.activeRequestFields)
  }
}

const resetForm = () => {
  formData.value = structuredClone(defaultFormData)
}
const resetLicenseData = () => {
  deleteUploadedFile('health_insurance_license')
  formData.value.license_link = ''
}
const onFileUploaded = (event = null, file_type) => {
  if (!event.target.files[0]) {
    return
  }

  requestFormData.set(`fields[${file_type}]`, event.target.files[0], event.target.files[0].name)
  uploadedFileName.value = event.target.files[0].name
  updateFormPayload()
}

const deleteUploadedFile = item => {
  requestFormData.delete(item)

  uploadedFileName.value = ''

  emit('delete-file', item)
}

const updateFormPayload = () => {
  emit('file-updated', requestFormData)
}

const onChange = () => {
  emit('changed', {fields: formData.value})
}

defineExpose({resetForm})
</script>

<template>
  <div class="form-group">
    <label for="financial_product-wl-type">Type of certificate (choose one) * </label>
    <div class="form-group">
      <the-select
        placeholder="Select type"
        v-model="formData.certificate_type"
        :options="certficationTypeOptions"
        :disabled="disabled"
        id="financial_product-wl-type"
        required
      ></the-select>
    </div>
  </div>
  <div class="form-group">
    <label for="financial_product-wl-geo">Geo *</label>
    <div class="input-group">
      <input
        v-model="formData.geo"
        @change="onChange"
        class="form-control"
        id="financial_product-wl-geo"
        type="text"
        :disabled="disabled"
        required
      />
    </div>
  </div>
  <div class="form-group">
    <label for="financial_product-wl-websites">Website(s) *</label>
    <div class="input-group">
      <textarea
        v-model="formData.websites"
        @change="onChange"
        :disabled="disabled"
        class="form-control"
        id="financial_product-wl-websites"
        rows="1"
        required
      />
    </div>
  </div>

  <div class="alert alert-light mb-3">
    <label :for="[`wl-financial_product_license-${licenseType === 'file' ? 'file-upload' : 'link'}`]"
      >All relevant licenses *
    </label>
    <div class="row">
      <div class="col">
        <div v-if="licenseType === 'file'" class="form-group">
          <div v-if="formData?.licenses_url">
            <span @click="openUrl(formData?.licenses_url)" class="full-width btn btn-outline-success">Open file </span>
          </div>
          <div v-else>
            <template v-if="!uploadedFileName">
              <template v-if="disabled">
                <div class="alert alert-info">No file</div>
              </template>
              <template v-else>
                <label for="wl-financial_product_license-file-upload" class="full-width btn btn-secondary">
                  <i class="fa fa-upload"></i> Add file
                </label>

                <input
                  class="d-none"
                  @change="onFileUploaded($event, 'licenses')"
                  id="wl-financial_product_license-file-upload"
                  :disabled="disabled"
                  type="file"
                />
              </template>
            </template>
            <template v-else>
              <span class="full-width btn btn-outline-success"
                >{{ uploadedFileName }}
                <button type="button" class="btn-close circle-btn" @click="deleteUploadedFile('licenses')" />
              </span>
            </template>
          </div>
        </div>
        <div v-else class="form-group">
          <input
            v-model="formData.license_link"
            type="url"
            placeholder="Add link"
            class="form-control"
            id="wl-financial_product_license-link"
            @change="deleteUploadedFile('licenses')"
          />
        </div>
      </div>
      <div class="col-3 pl-0" v-if="!activeRequestFields">
        <the-select
          v-model="licenseType"
          :options="licenseTypeOptions"
          @change="resetLicenseData"
          placeholder=""
          :allow_clear="false"
        ></the-select>
      </div>
    </div>
  </div>
  <div class="form-group" v-if="!activeRequestFields">
    <span class="text-danger">* Please refer to the file for additional information: </span>
    <a :href="`${publicPath}files/crypto-google.xlsx`" download="Example.xlsx" class="text-decoration-none">
      <span class="text-decoration-underline">Example.xlsx </span> <i class="fa fa-download"></i
    ></a>
  </div>
</template>
