<script setup>
import {defineProps, ref, onMounted, defineEmits, computed} from 'vue'
import TheModal from '@/components/ui/TheModal.vue'
import BaseForm from '@/components/BaseForm.vue'
import {useNotification} from '@kyvg/vue3-notification'
import TheSelect from '@/components/ui/TheSelect.vue'
import {storeToRefs} from 'pinia'
import {useCompanyStore} from '@/views/companies/CompanyStore'
import {useCompanyBillingStore} from '@/views/invoices-main/CompanyBillingStore'
import ApiErrors from '@/components/ui/ApiErrors.vue'
import {useAuthenticationStore} from '@/stores'

const {companies} = storeToRefs(useCompanyStore())
const {createOverdraft} = useCompanyBillingStore()
const {getCompanies} = useCompanyStore()
const {isCoreTeam} = storeToRefs(useAuthenticationStore())

// Props & emits
const props = defineProps({
  payment: {
    type: Object,
    required: false,
  },
  parentModalId: {
    type: String,
    default: '',
  },
  id: {
    type: String,
    default: null,
  },
})
const emit = defineEmits(['submitted', 'hidden'])
const modalId = props.id ?? 'overdraft-modal'
const {notify} = useNotification()
const error = ref(false)
const loading = ref(false)

// Refs
const theModal = ref()
const defaultFormData = {sum: null, reason: '', company_hash: null}
const formData = ref(structuredClone(defaultFormData))

// Computed
const companiesOptions = computed(() => companies.value.map(company => ({value: company.hash, label: company.title})))

// Methods
onMounted(() => {
  init()

  openModal()

  if (isCoreTeam.value) {
    getCompanies()
  }
})

const init = () => {
  formData.value = structuredClone(defaultFormData)
}
const openModal = () => {
  theModal.value.handleOpenModal()
}

const closeModal = () => {
  theModal.value.handleCloseModal()
}

const handleSubmit = async () => {
  loading.value = true

  try {
    await createOverdraft(formData.value)

    notify({type: 'success', text: 'Your application has been sent to the responsible employees for approval'})
    emit('submitted')
    closeModal()
  } catch (e) {
    error.value = e.data
  } finally {
    loading.value = false
  }
}

const emitHidden = () => {
  emit('hidden')
}
</script>

<template>
  <TheModal
    ref="theModal"
    @hidden="emitHidden"
    :id="modalId"
    :parent-modal-id="parentModalId"
    title="Overdraft request"
  >
    <base-form @on-submitted="handleSubmit">
      <div class="modal-body">
        <div v-if="!props.payment" class="form-group">
          <label for="overdraft-company">Company *</label>
          <div class="form-group">
            <the-select
              v-model="formData.company_hash"
              :options="companiesOptions"
              placeholder="Select a Company"
              id="overdraft-company"
              required
            />
          </div>
        </div>
        <div class="form-group">
          <label for="overdraft-sum">Sum *</label>

          <input v-model="formData.sum" class="form-control" id="overdraft-sum" type="number" min="500" required />
        </div>
        <div class="form-group">
          <label for="overdraft-reason">Reason *</label>

          <textarea v-model="formData.reason" class="form-control" id="overdraft-reason" rows="3" required></textarea>
        </div>
        <api-errors v-if="error" :error="error"></api-errors>
      </div>
      <div class="modal-footer justify-content-between">
        <button class="btn btn-primary" :disabled="loading">Submit</button>
        <button type="button" class="btn btn-secondary" @click="closeModal">Close</button>
      </div>
    </base-form>
  </TheModal>
</template>
